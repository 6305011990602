import React, { useEffect, useState } from "react";
import logo from "../assets/img/logo.svg";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useSiteContentQuery } from "../store/services/SitesettingService";
let imageUrl = "https://backend-rasani.developer-ourbase-camp.com/uploads/";

const Header = (props) => {
	const { data, isLoading } = useSiteContentQuery();
	let response = data?.response?.data;
	const { page } = props;
	useEffect(() => {
		$("document").ready(function () {
			$(".canvas-icon").click(function () {
				$("header#mobileheader").show(".ti");
			});
			$(".cancel").click(function () {
				$("header#mobileheader").hide(".ti");
			});
		});
	}, []);
	return (
		<>
			{/* // < Header start here > */}
			<header id="header-top" className={page != "home" ? "bg-white" : null}>
				<div className="container">
					<div className="header-sec">
						<div className="row align-items-center">
							<div className="col-lg-4">
								<div className="logo-main">
									<figure>
										<Link to="/">
											<img
												src={`${imageUrl}${response?.logo}`}
											></img>
										</Link>
									</figure>
								</div>
							</div>
							<div className="col-lg-8">
								<div className="nav-bar">
									<ul className="navbar">
										<li>
											<Link to="/aboutus">ABOUT US (WHY RASANI)</Link>
										</li>

										<li>
											<Link to="/services">SERVICES</Link>
										</li>
										<li>
											<Link to="/leaderbuy">LEAD BUYER</Link>
										</li>
										<li>
											<Link to="/affilate">AFFILIATES</Link>
										</li>
										<li>
											<Link to="/privacy">PRIVACY POLICY</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="canvas-icon">
					<i className="fa fa-bars" aria-hidden="true"></i>
				</div>
			</header>
			{/* mobile header */}
			<header id="mobileheader">
				<div className="cancel">
					<i className="fa fa-window-close" aria-hidden="true"></i>
				</div>
				<div className="container">
					<div className="header-sec-mobile">
						<div className="row align-items-center">
							<div className="col-lg-12">
								<div className="nav-bar-mobile">
									<ul className="navbar-mobile">
										<li>
											<Link to="/aboutus">ABOUT US (WHY RASANI)</Link>
										</li>
										<li>
											<Link to="/services">SERVICES</Link>
										</li>
										<li>
											<Link to="/leaderbuy">LEAD BUYER</Link>
										</li>
										<li>
											<Link to="/affilate">AFFILIATES</Link>
										</li>
										<li>
											<Link to="/privacy">PRIVACY POLICY</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	);
};

export default Header;
