import React, { useEffect } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Contact from "../../Components/Contact";
import thirdsec from "../../assets/img/thirdsec.webp";
import phone from "../../assets/img/phone.webp";
import Line from "../../assets/img/Line.webp";
import { Link } from "react-router-dom";
import "animate.css";
import WOW from "wow.js";
import { useHomeContentQuery } from "../../store/services/HomeServices";
import { useServiceContentQuery } from "../../store/services/Services";
let imageUrl = "https://backend-rasani.developer-ourbase-camp.com/uploads/";

const Home = () => {
	const { data, isLoading } = useHomeContentQuery();
	const { data: serviceData, isLoading: serviceLoading } =
		useServiceContentQuery();

	let response = data?.response?.data;
	let serviceResponse = serviceData?.response?.data;
	useEffect(() => {
		new WOW().init();
	}, []);
	return (
		<>
			<Header page={"home"} />
			<div className="Banner">
				<div className="container">
					<div className="banner-sec">
						<div className="row">
							<div className="col-lg-5">
								<div className="banner-content">
									<div className="about-content">
										<img className="line" src={Line}></img>
										<h6 className="wow animate__animated animate__bounce">
											{response?.section_one_sub_heading}
										</h6>
									</div>
									<div className="heading-para">
										<h2 className="wow animate__animated animate__backInLeft">
											{response?.section_one_heading}
										</h2>
										<p className=" wow animate__animated animate__backInLeft">
											{response?.section_one_text}
										</p>
									</div>
									<div className="banner-btn">
										<button className="btn">
											<Link to="/aboutus">Get Started</Link>
										</button>
									</div>
								</div>
							</div>
							<div className="col-lg-7">
								<div className="banner-img-erapper">
									<div className="img-content">
										<figure>
											<img
												src={`${imageUrl}${response?.section_one_image}`}
											></img>
										</figure>
									</div>
									{/* <div className="manycheckbox">
										<div className="live">
											<button className="livebtn">
												<img src={Grouparrow}></img>
												<h5>Digital Leads</h5>
											</button>
										</div>
										<div className="digital">
											<button className="digitalbtn">
												<img src={Grouparrow}></img>
												<h5>Live Transfers</h5>
											</button>
										</div>
										<div className="data">
											<button className="databtn">
												<img src={Grouparrow}></img>
												<h5>Purchase Data</h5>
											</button>
										</div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<section className="lead">
				<div className="container">
					<div className="lead-wrapper">
						<div className="row">
							<div className="col-lg-4 col-md-4 leadmargin">
								<div className="circle-content">
									<h4>{response?.section_two_daily_transfer}</h4>
								</div>
								<div className="content-para">
									{/* <h4>Leads Generated</h4> */}
									<p>{response?.section_one_item_one}</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 leadmargin">
								<div className="circle-content">
									<h4>{response?.section_two_monthly_transfer}</h4>
								</div>
								<div className="content-para">
									{/* <h4>Call Generated Per Day</h4> */}
									<p>{response?.section_one_item_two}</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 leadmargin">
								<div className="circle-content">
									<h4>{response?.section_two_yearly_transfer}</h4>
								</div>
								<div className="content-para">
									{/* <h4>Total Balances Of Leads Generated</h4> */}
									<p>{response?.section_one_item_three}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="thirdsec">
				<div className="container">
					<div className="thirdsec-sec">
						<div className="row">
							<div className="col-lg-7 col-md-6">
								<div className="thirdsec-img-erapper">
									<div className="img-content">
										{/* <img src={thirdsec}></img> */}
										<img
											src={`${imageUrl}${response?.section_three_image}`}
											alt=""
											className="img-fluid"
										/>
									</div>
								</div>
							</div>
							<div className="col-lg-5 col-md-6">
								<div className="thirdsec-content">
									<div className="about-content">
										<img className="line" src={Line}></img>
										<h6 className="wow animate__animated animate__bounce">
											{response?.section_three_sub_heading}
										</h6>
									</div>
									<div className="heading-para">
										<h2 className="wow animate__animated animate__backInRight">
											{response?.section_three_heading}
										</h2>
										<p className="wow animate__animated animate__backInRight">
											{response?.section_three_text
												? response?.section_three_text
												: "Empty data"}
										</p>
									</div>
									<div className="banner-btn">
										<button className="btn">
											<Link to="/Affilate">Get Started</Link>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* our service section  */}

			<section className="our-service-section">
				<div className="container">
					<div
						className="rasani-head1
           for-head-account-flex ours-service"
					>
						<div className="line-head">
							<img className="img-fluid" src={Line}></img>
							<h5>{response?.section_four_sub_heading}</h5>
						</div>
						<h2>{response?.section_four_heading}</h2>
					</div>
					<div className="row">
						{serviceResponse?.map((data, index) => {
							return (
								<div className="col-lg-4 col-md-6" key={data?.id}>
									<div className="all-box wow animate__animated animate__backInUp">
										<div className="dv-img">
											<img
												className="img-fluid"
												src={`${imageUrl}${data?.icon}`}
											/>
										</div>
										<div className="dv-head">
											<h3>{data?.title}</h3>
										</div>
										<div className="dv-para">
											<p
												dangerouslySetInnerHTML={{
													__html: data?.description,
												}}
											></p>
											<div className="btn">
												<span className="paramoving">
													<Link to="/services">Learn More...</Link>
												</span>
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</section>
			{/* End of service section  */}

			<Contact />
			<Footer />
		</>
	);
};

export default Home;
