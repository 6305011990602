export const Url = "https://backend-rasani.developer-ourbase-camp.com/api/";
export const HomeEndpoint = "home";
export const ServiceEndpoint = "services";
export const LeaderEndpoint = "lead-buyer";
export const AboutEndpoint = "about-us";
export const AffiliateEndpoint = "our-affiliates";
export const PrivacyEndpoint = "privacy-policy";
export const TermEndpoint = "terms-of-service";
export const SitesettingEndpoint = "site-settings";
export const ContactEndpoint = "contact";
export const InterestEndpoint = "interest-form";
